//companies slider
import image1 from '../assets/1-dcj.webp'
import image2 from '../assets/2-wrightsure.webp'
import image3 from '../assets/3-jbs.webp'
import image4 from '../assets/4-fnc.webp'
import image5 from '../assets/5-russell.webp'
import image6 from '../assets/6-whittlesey.webp'
import image7 from '../assets/7-htc.webp'
import image8 from '../assets/8-specialist.webp'
import image9 from '../assets/9-sib.webp'
import image10 from '../assets/10-trdirect.webp'
import image11 from '../assets/11-jabrokers.webp'
import image12 from '../assets/12-bislogo.webp'
import image13 from '../assets/13-hinks.webp'
import image14 from '../assets/14-west-craven.webp'
import image15 from '../assets/15-wcib.webp'
import image16 from '../assets/16-business-compare.webp'

//paragraph slider
import paragraph1 from '../assets/paragraph1.jpeg'
import paragraph2 from '../assets/paragraph2.jpg'
import paragraph3 from '../assets/paragraph3.jpeg'
import paragraph4 from '../assets/paragraph4.jpg'
import paragraph5 from '../assets/paragraph5.jpeg'
import paragraph6 from '../assets/paragraph6.jpeg'
import paragraph7 from '../assets/paragraph7.jpeg'
import paragraph8 from '../assets/paragraph8.jpeg'
import paragraph9 from '../assets/paragraph9.jpg'

import services1 from '../assets/services-column1.webp'
import services2 from '../assets/services-column2.webp'
import services3 from '../assets/services-column3.webp'

export const paragraphSlider = {
    theme: 'dark',
    title: 'Our Products',
    description: '',
    bgColor: "#005d8f",
    paragraph: [
        {
            image: paragraph1,
            subtitle: "Motor Legal Expenses",
            info: "When you are involved in a non-fault accident, it takes just one call to us for our team to immediately jump into action to make sure you can get back on the roads as soon as possible, minimising disruption for you.",
            label: "FIND MORE",
            url: "/motor-legal-expenses/",
        },
        {
            image: paragraph2,
            subtitle: "Motor Breakdown Recovery",
            info: "We understand how important it is to have the right motor breakdown cover for your needs. Which is why our products are designed to keep you on the move. We provide three levels of cover, covering the United Kingdom and Europe.",
            label: "FIND MORE",
            url: "/motor-breakdown-recovery/",
        },
        {
            image: paragraph3,
            subtitle: "Guaranteed Replacement Vehicle Insurance",
            info: "Our guaranteed replacement vehicle insurance is the answer. We will ensure your client’s stay on the road when this happens, by providing peace of mind.",
            label: "FIND MORE",
            url: "/guaranteed-replacement-vehicle-insurance/",
        },
        {
            image: paragraph4,
            subtitle: "Excess Protection",
            info: "We can offer Excess Protection Insurance products.",
            label: "FIND MORE",
            url: "/excess-protection/",
        },
        {
            image: paragraph5,
            subtitle: "Landlord Legal Protection",
            info: "Our Landlord Professional Fees policy, is available to any landlord purchasing a buy to let residential property Insurance.",
            label: "FIND MORE",
            url: "/landlord-legal-protection/",
        },
        {
            image: paragraph6,
            subtitle: "Family Legal Expenses",
            //label: "FIND MORE",
            //url: "/family-legal-expenses/",
        },
        {
            image: paragraph7,
            subtitle: "Landlord Legal Expenses (& Rent Guarantee)",
            //label: "FIND MORE",
            //url: "/landlord-legal-expenses-rent-guarantee/",
        },
        {
            image: paragraph8,
            subtitle: "Commercial Legal Expenses",
            //label: "FIND MORE",
            //url: "/commercial-legal-expenses/",
        },
        {
            image: paragraph9,
            subtitle: "Home Emergency",
            //label: "FIND MORE",
            //url: "/home-emergency/",
        },
    ]
}

export const cardsProducts = {
    comparison: [
        {
            theme: "light",
            background: services1,
            bgColor: "#015D8C",
            subtitle: "UNINSURED LOSS RECOVERY",
            info: "Flexible claims management solutions tailored to individual requirements.",
            label: "LEARN MORE",
            url: "/uninsured-loss-recovery/"
        },
        {
            theme: "dark",
            background: services2,
            bgColor: "#127F91",
            subtitle: "CREDIT HIRE & CREDIT REPAIR",
            info: "Bespoke service to manage your company’s fleet, minimising unnecessary risk.",
            label: "LEARN MORE",
            url: "/credit-hire-and-credit-repair/"
        },
        {
            theme: "light",
            background: services3,
            bgColor: "#015D8C",
            subtitle: "THIRD-PARTY INTERVENTION",
            info: "24-hour claims reporting line, where we support clients through a stressful process.",
            label: "LEARN MORE",
            url: "/third-party-intervention/"
        },

    ]
}

export const sliderCompaniesProducts = {
    theme: 'light',
    title: 'TRUSTED BY HUNDREDS',
    description: '',
    images: [
        {
            image: image1,
        },
        {
            image: image2,
        },
        {
            image: image3,
        },
        {
            image: image4,
        },
        {
            image: image5,
        },
        {
            image: image6,
        },
        {
            image: image7,
        },
        {
            image: image8,
        },
        {
            image: image9,
        },
        {
            image: image10,
        },
        {
            image: image11,
        },
        {
            image: image12,
        },
        {
            image: image13,
        },
        {
            image: image14,
        },
        {
            image: image15,
        },
        {
            image: image16,
        },
    ]
}
