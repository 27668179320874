import React from "react"
import 'uikit/dist/css/uikit.css'
import Layout from "../layouts"
import { cardsProducts, paragraphSlider, sliderCompaniesProducts } from "../utils/productsInformation";

import ImageCarousel from "../components/image-carousel/image-carousel";
import Comparison from "../components/comparison/comparison";




// markup
const Products = () => {


    return (
        <Layout
            SEOTitle={"Products - United Legal Assistance"}
            SEODescription={"When you are involved in a non-fault accident, it takes just one call to us for our team to immediately jump into action to make sure you can get back on the roads."}
            footerDisclaimer={""}>

            <ImageCarousel props={paragraphSlider} />

            <Comparison props={cardsProducts} />

            <ImageCarousel props={sliderCompaniesProducts} />

        </Layout>
    )

}

export default Products